import React from "react";
import "../styles/Oratory.css";
const teamimg = require("../assets/Team.jpeg");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const oratoryLogo = require("../assets/oratory-logo.png");
function Oratory() {
  return (
    <>
      <div className="oratory-section">
        <div className="header">
          <h1>Oratory Club</h1>
        </div>
        <div className="text1">
          <p>
            The Oratory Club is your one-stop for all kinds of events that
            celebrate each aspect of public speaking - be it logical debates or
            unhinged, spontaneous and informal stuff like Just A Minute (JAM)
            and STEW. The club participates in tournaments and fests in colleges
            across the country and conducts events for students within and
            outside the institute. The first edition of the IITM Parliamentary
            Debate was launched in 2024, a massive event that witnessed almost
            200 students in colleges all over India engage in a weekend of
            quality debating.
          </p>
        </div>
        <div className="img1">
          <img
            alt="Team1"
            style={{ width: "350px", paddingLeft: "150px"}}
            src={String(teamimg)}
          />
        </div>
        <div className="img2">
          <img
            alt="Team1"
            style={{ width: "350px"}}
            src={String(teamimg)}
          />
        </div>
        <div className="img3">
          <img
            alt="Team1"
            style={{ width: "350px"}}
            src={String(teamimg)}
          />
        </div>
        <div className="text2">
          <p>
            "Stepping in, you're not just joining a team – you're entering a
            wonderfully dysfunctional family. Every practice session is a
            chaotic yet brilliant brainstorming extravaganza, and not a single
            moment is dull when you're surrounded by this crazy, lively bunch!"
          </p>
        </div>
      </div>
      <footer className="clubs-footer">
        <img src={String(oratoryLogo)} alt="oratory Logo" style={{marginRight:'50px'}} />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/oratory_club_iitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:oratory@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:7973098889" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Oratory;
