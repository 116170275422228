import React from "react";
import "../styles/Music.css";
const musImg = require("../assets/Classicalartteam.jpeg");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const musicLogo = require("../assets/music-logo.png");

function Music() {
  return (
    <>
      <div className="music-section">
        <div className="music-head">
          <p className="mus-head2">music</p>
          <p className="mus-head1">CLUB</p>
        </div>
        <div className="music-img">
          <p>
            All in all, Music Club at IIT Madras offers a space for you to
            develop your musical skills, meet like-minded people, get
            performance experience, and have a creative outlet by not only
            performing it
          </p>
          <img src={String(musImg)} />
          <p>
            but also organising activities and events to spread the music
            culture both inside and outside the campus.
          </p>
        </div>
        <div className="music-text">
          <p>
            The IITM Music Club is a lively community where students can share
            and enjoy their love for music. We welcome everyone, whether you're
            a seasoned musician or just someone who loves music. Our goal is to
            create a supportive environment where members can grow, collaborate,
            and showcase their talents. Throughout the year, we host many events
            like open mic nights, talent shows, and concerts. These give our
            members the chance to perform and gain experience in front of an
            audience. We also encourage collaboration through group projects
            like forming bands, writing songs together, and putting on ensemble
            performances.
          </p>
        </div>
      </div>
      <footer className="clubs-footer">
        <img
          src={String(musicLogo)}
          alt="musicLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/quizclubiitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:quiz@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:6300290930" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Music;
