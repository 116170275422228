import React from 'react'
import '../styles/Classical.css'
const classicalTeam = require('../assets/Classicalartteam.jpeg');
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const classicalLogo = require("../assets/classical-logo.png");
function Classical() {
  return (
    <>
      <div className="classical-section">
        <div className="background-blur"></div>
        <div className="content">
          <h1 className="classical-h1">Classical Arts</h1>
          <div className="content-section">
            <div>
              <img className='classical-team'
                alt="Team1"
                src={String(classicalTeam)}
              />
            </div>
            <div className="text-box">
              <p className="classical-text">
                The Classical Arts Club, IIT Madras, is a student led
                organization intended at educating, promoting, and preserving
                the diverse classical performing art forms of India, with an
                emphasis on Classical Music and Dance. Its vision is to act as
                the one-stop destination for any member of the IIT Madras
                student community to get involved in the Classical Arts, from
                the enthusiastic neophytes to those who have been practicing the
                art form for most of their lives. As such, it is involved in
                educating the student body of IIT Madras about the beauty of the
                Classical Arts, while promoting the finest student talent in the
                IIT Madras community.
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <img src={String(classicalLogo)} alt="classical Logo" style={{marginRight:'50px'}}/>
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/classicalartsclub_iitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:classicalarts@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:9421730569" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Classical
