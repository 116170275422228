import React from 'react';
import './clubs.css';
import clubs from '../assets/clubs.jpeg';
import ExploreClubs from './exploreclubs';


function Clubs() {
  return (
    <div>
    <div className="clubsContainer"  >
      <div className="clubs-background-text" >SANGAM</div>
      <div className="left-container">
        <h1 className='left-heading'  id='clubstext' >CLUBS</h1>
        <p className='left-para'>Sangam, is the conglomeration of the 17 clubs  that cater to a wide variety of art forms and their enthusiasts within the Institute. All you need is enthu, your art will find its way to you!</p>
        <img src={clubs} alt="clubs image" className='left-image' />
      </div>
      <div className="right-container">
        <h2 className='right-heading'>EVENTS</h2>
        <p className='right-para'>Each Sangam Club hosts its flagship and other events throughout the semesters, thus ensuring an immersive engagement of the GSB in the cultural scene. With a sprinkle of healthy competition and lucrative take-aways, you are in for a great time!</p>
        <h2 className='right-heading'>CLUB SESSIONS AND INITIATORS</h2>
        <p className='right-para'>Nervous a out starting off on your cultural endeavours? Fret not, since we’ve got you covered with our initiators and workshops. They help you get acquainted to the art form and after a few sessions, you find yourself falling in love with art!</p>
        <h2 className='right-heading'>WORKSHOPS</h2>
        <p className='right-para'>Love what you are doing and want to explore further? That’s what our workshops do - they give you new perspectives of your art form and nurture your growth in it, with it!</p>
        <button className="clubexploreButton" ><a href="#clubs">EXPLORE CLUBS</a></button>
      </div>

       
    </div>
    <ExploreClubs />
    </div>
  );
}

export default Clubs;
