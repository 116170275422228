import React from 'react';
import './incubator.css';
import fashionDesign from '../assets/fashion_design.png';
import creativeCrafts from '../assets/creative_crafts.png';
import urbanSketching from '../assets/urban_sketching.png';
import sanskriti from '../assets/sanskriti.png';
import silentReadingClub from '../assets/silent_reading_club.png';
import journalismEnthusiasts from '../assets/journalism_enthusiasts.png';
import sudokuCommunity from '../assets/sudoku_community.png';
import crochet from '../assets/crochet.png';
import otakuSociety from '../assets/otaku_society.png';
import everythingHipHop from '../assets/everything_hip_hop.png'; 
import filmAppreciationClub from '../assets/film_appreciation_club.png'; 

function Incubator() {
  return (
    <div className='incubator-container'>
      <h1 className='incubator-title' id='incubator'>SANGAM INCUBATOR</h1>
      
      <div className="row">
        <div className="item right">
          <img src={fashionDesign} alt="Fashion Design" className='incubator-img'/>
          <span>Fashion Design</span>
        </div>
        <div className="item right">
          <img src={creativeCrafts} alt="Creative Crafts" className='incubator-img'/>
          <span>Creative Crafts</span>
        </div>
        <div className="item right">
          <img src={urbanSketching} alt="Urban Sketching" className='incubator-img'/>
          <span>Urban Sketching</span>
        </div>
        <div className="item right"> 
          <img src={everythingHipHop} alt="Everything Hip Hop" className='incubator-img'/>
          <span>Everything Hip Hop</span>
        </div>
      </div>

      <div className="row">
        <div className="item left">
          <span>Sanskriti</span>
          <img src={sanskriti} alt="Sanskriti" className='incubator-img'/>
        </div>
        <div className="item left">
          <span>Silent Reading Club</span>
          <img src={silentReadingClub} alt="Silent Reading Club" className='incubator-img'/>
        </div>
        <div className="item left">
          <span>Journalism Enthusiasts</span>
          <img src={journalismEnthusiasts} alt="Journalism Enthusiasts" className='incubator-img'/>
        </div>
        <div className="item left"> 
          <span>Film Appreciation Club</span>
          <img src={filmAppreciationClub} alt="Film Appreciation Club" className='incubator-img'/>
        </div>
      </div>

      <div className="row">
        <div className="item right">
          <img src={sudokuCommunity} alt="Sudoku Community" className='incubator-img'/>
          <span>Sudoku Community</span>
        </div>
        <div className="item right">
          <img src={crochet} alt="Crochet" className='incubator-img'/>
          <span>Crochet</span>
        </div>
        <div className="item right otaku">
          <img src={otakuSociety} alt="Otaku Society" className='incubator-img'/>
          <span>Otaku Society</span>
        </div>
      </div>
    </div>
  );
}

export default Incubator;
