import React from 'react'
import '../styles/Comedy.css'
const Comedyman=require('../assets/Comedyman.jpg')
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const comedyLogo = require("../assets/comedy-logo.png");
function Comedy() {
  return (
    <>
      <div className="comedy-section">
        <h1 className="comedy-h1">Comedy Club</h1>
        <div className="main-content">
          <div>
            <img alt="Man" className="man-img" src={String(Comedyman)} />
          </div>
          <div className="text-comedy">
            <p className='comedy-p'>
              The Comedy Club of IIT Madras is a student-driven club here to
              make everyone laugh their hearts out. With stand-ups, sketches and
              so on, the club conducts many live performances at various fests
              to keep the audience entertained. The club’s beginner-friendly
              workshops are something to look out for all those who want to
              learn the art of comedy.
            </p>
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <img src={String(comedyLogo)} alt="comedyLogo" />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/comedy_club_iitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:comedy@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:7082022802" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Comedy
