import React from "react";
import "../styles/Fashion.css";
const fImg = require("../assets/choreo2.jpeg");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const fashionLogo = require("../assets/fashion-logo.png");
function Fashion() {
  return (
    <>
      <div className="fashion-section">
        <div className="f-head">
          <h1>FashionClub.</h1>
        </div>
        <div className="f-img">
          <img src={String(fImg)} />
        </div>
        <div className="f-text">
          <p>
            Fashion Club promotes the growth of a fashion culture inside IIT
            Madras. It also gives an avenue for every student to express
            themselves and showcase the way they carry themselves. Fashion
            Club's events act as a catalyst for each and every student to
            interact with the world of fashion, through a myriad of ways like
            utilising the practice of thrifting, or participating in hostel
            rampwalks. Fashion Club is your key to entering the world of
            fashion, in insti and beyond.
          </p>
        </div>
      </div>
      <footer className="clubs-footer">
        <img
          src={String(fashionLogo)}
          alt="fashionLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/fashion_iitmadras?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:fashion@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="..." target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Fashion;
